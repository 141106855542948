.compare{
	background: #eee;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: none;
	z-index: 8;
	height: calc(100% - 128px);
	padding-top: 128px;
	.compare-left, .compare-right{
		float: left;
		width: 50%;
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	.items{
		padding: 20px;
		p:first-child{
			padding-top: 20px;
			margin-top: 0;
		}
	}
	.fixed{
		.header{
			z-index: 3;
			position: absolute !important;
			width: 50%;
			top: 128px;
			&.frieze{
				top: -98px !important;
			}
		}
	}
	.compare-left{
		.items{
			margin-right: 2%;
			background: #fff;
		}
		.header .info{
			padding-left: 3%;
			width: 89%;
		}
		.header.frieze .info{
			margin-right: 2%;
			width: 89%;
		}
	}
	.compare-right{
		.items{
			margin-left: 2%;
			background: #fff;
		}
		.header .info{
			padding-left: 3%;
			width: 89%;
			margin-left: 2%;
		}
		.header.frieze .info{
			margin-left: 2%;
			padding-left: 3%;
			width: 89%;
		}
	}
}
