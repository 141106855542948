.image{
	overflow: hidden;
	position: relative;
	img{
		width: 100%;
		vertical-align: middle;
		height: auto;
	}
	.img{
		overflow: hidden;
		margin-bottom: 10px;
		border-radius: 5px;
		position: relative;
	}
	h3, .des{
		font-style: italic;
		margin-bottom: 5px;
		margin-top: 0;
	}
	&.zoom .img{
		cursor: zoom-in;
		&.down{
			cursor: zoom-out;
		}
	}
}
