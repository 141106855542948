.timeline{
	position: relative;
	padding-top: 77px;
	.scroll{
		z-index: 6;
		position: fixed;
		height: 43px;
		background-color: #383838;
		bottom: 0;
		width: 100%;
		user-select: none;
		.full{
			position: relative;
			width: 80%;
			height: 43px;
			margin: 0px auto;
		}
		.cur{
			position: absolute;
			user-select: none;
			top: 7px;
			left: 0;
			height: 25px;
			width: 0;
			background: fade-out($color-orange, 0.8);
			border: 2px solid $color-orange;
			border-radius: 5px;
			display: none;
			z-index: 1;
			text-align: center;
			cursor: move;
			svg{
				vertical-align: middle;
				pointer-events:none;
			}
		}
		.btn-wrap{
			float: left;
			height: 30px;
		}
		.btn{
			margin: 12px 6px;
			background-color: #2c2c2c;
			height: 19px;
			border-radius: 3px;
			position: relative;
			text-align: center;
			.tooltip{
				display: none;
				position: absolute;
				width: 150px;
				margin-left: -79px;
				font-size: 90%;
				line-height: 100%;
				padding: 5px;
				border-radius: 3px;
				bottom: 35px;
				background: #fff;
				box-shadow: 0 1px 3px #000;
				h3{
					font-size: 90%;
					line-height: 100%;
				}
				.tri{
					position: absolute;
					left: calc(50% - 8px);
					bottom: -8px;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid #fff;
				}
			}
			&:hover{
				.tooltip{
					display: inline-block;
				}
			}
		}
		&.active{
			.cur{
				display: block;
			}
		}
	}
}
