.loader {
	z-index: 1000;
  margin: -51px 0 0 -31px;
  left: 50%;
  font-size: 10px;
  position: absolute;
  top: 50%;
  text-indent: -9999em;
  border: 3px solid fade-out($color-orange, 0.75);
  border-left: 3px solid $color-orange;
  transform: translateZ(0);
  animation: load 1.1s infinite linear;
  transition: opacity 300ms, visibility 300ms, transform 300ms;
  opacity: 1;
  visibility: visible;
  &.hide{
  	opacity: 0;
	  visibility: hidden;
  }
}
.loader-text {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top:26px;
	margin-left:-100px;
	width: 200px;
	font-size: 14px;
	line-height: 1em;
	text-align: center;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
