.hide {
	display: none;
}

body, html{
	padding: 0;
	margin: 0;
	font-family: franklin-gothic-urw-comp, sans-serif;
	// user-select: none;
	line-height: 1.5em;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	color: #666;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}
::-webkit-scrollbar:horizontal {
	height: 0px;
}

::-webkit-scrollbar:vertical {
	height: 16px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    background: #ccc!important;
}

::-webkit-scrollbar-corner {
    -webkit-appearance: none;
    background: #ccc!important;
}

::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: #383838!important;
}

input{
	font-family: franklin-gothic-urw-comp, sans-serif;
	line-height: 1.5em;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	outline: none;
	width: 96%;
	border: none;
	padding: 2%;
}

h2,h3{
	font-size: 1.2em;
	margin: 0;
	font-weight: 300;
	color: #383838;
}
h1,h2,h3,p{
	user-select: default !important;
}

#scroll-eye, #next-arrow, #prev-arrow {
	fill: $color-orange;
}

.list-sec{
	background: #eee;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 150px);
	display: none;
	padding-top: 150px;
	z-index: 9;
	.periods, .articles, .article{
		background: #fff;
		float: left;
		height: 100%;
		width: 32%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	.periods, .articles{
		margin-right: 2%;
	}
	.article{
		.items{
			padding: 20px;
		}
	}
}
.listitem{
	padding: 20px;
	cursor: pointer;
	border-bottom: 1px solid #eee;
	h3{
		font-size: 0.7em;
		line-height: 1em;
		margin-bottom: 0.2em;
	}
	&.active{
		background: url(../assets/images/list-arrow.svg) right center no-repeat;
		h2{
			color: $color-orange;
		}
	}
}

.info, .image, .column, .sequence{
	margin-bottom: 40px;
}

.search-item{
	min-height: 50px;
	margin: 10px 0;
	cursor: pointer;
	.thumb{
		float: left;
		width: 50px;
		margin-right: 10px;
		height: 50px;
		border-radius: 5px;
	}
	h3{
		margin-bottom: 0;
	}
	p{
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: none;
	}
	p:first-child {
		display:block;
	}
}
.compact{
	.column{
		.col1,.col2{
			float: none;
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
	.header.frieze{
		height: 300px;
		position: relative;
		background-size: cover;
		.info{
			position: absolute;
			bottom: 0;
			width: 88%;
			background: #444;
		}
	}
	.header > .info{
		background: #444;
		padding: 10px 20px;
		margin-bottom: 0;
		h2{
			color: $color-orange;
		}
		p{
			margin: 0;
			color: #fff;
		}
	}
}

.bg{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-size: cover;
}

.clear{
	clear: both;
}
