@font-face {
    font-family: 'icomoon';
    src:
        url('../assets/fonts/icons/icomoon.woff') format('woff'),
        url('../assets/fonts/icons/icomoon.ttf') format('truetype'),
        url('../assets/fonts/icons/icomoon.svg#icomoonregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class*="-circled"] {
    position: relative;
    backface-visibility: hidden;
    transform: translateZ(0);
}

[class^="icon-"][class*="-circled"]::after,
[class*=" icon-"][class*="-circled"]::after {
    box-sizing: border-box;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    border-color: inherit;
}

.icon-student-tool:before {
  content: "\e900";
}
.icon-factbox:before {
  content: "\e906";
}
.icon-external-link:before {
  content: "\e912";
}
.icon-group-task:before {
  content: "\e916";
}
.icon-individual-task:before {
  content: "\e917";
}
.icon-pair-task:before {
  content: "\e918";
}
.icon-class-task:before {
  content: "\e919";
}
.icon-arrow-down:before {
  content: "\e612";
}
.icon-task:before {
  content: "\e901";
}
