.column{
	.col1,.col2{
		float: left;
		width: 46%;
	}
	.col1{
		margin-right: 4%;
	}
	.col2{
		margin-left: 4%;
	}
}
