.sequence {
	position: relative;

	word-break: break-word;
	hyphens: auto;

	.info,
	.image,
	.column,
	.sequence {
		margin-bottom: 0;
	}

	.tabs-scroll {
		position: relative;
		overflow: hidden;
		padding-bottom: 5px;
		margin-bottom: 15px;
	}

	.next,
	.prev {
		width: 100px;
		height: 74px;
		position: absolute;
		top: 0;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		transition: opacity 300ms, visibility 300ms;
		cursor: pointer;

		&.active {
			opacity: 1;
			visibility: visible;
		}
	}

	.next {
		right: 0;
	}

	.prev {
		left: 0;
	}

	.tabs > div{
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		float: left;
		width: 110px;
		height: 70px;
		margin-right: 20px;
		cursor: pointer;
		border: 2px solid transparent;
		border-radius: 5px;

		&.active{
			border-color: $color-orange;
		}
	}

	.tabs-con {
		margin-bottom: 20px;
		position: relative;

		.arrow-left,
		.arrow-right {
			display: block !important;
			width: 73px;
			height: 91px;
			background: url(../assets/images/arrow.png) no-repeat center center;
			background-size: contain;
			cursor: default;
			position: absolute;
			left: 11px;
			top: 50%;
			margin: -45px 0 0 0;
			transform: scale(0.8) translateZ(0);
			transform-origin: center center;
			pointer-events: none;
			user-select: none;
			transition: opacity 125ms linear, transform 125ms ease-out;
			opacity: 0.25;
			backface-visibility: hidden;
		}

		.arrow-right {
			left: auto;
			right: 11px;

			&.active {
				opacity: 0.85;
				pointer-events: all;
				cursor: pointer;

				&:hover {
					opacity: 1;
					transform: scale(1) translateZ(0);
				}
			}
		}

		.arrow-left {
			transform: scale(-0.8);

			&.active {
				opacity: 0.85;
				pointer-events: all;
				cursor: pointer;

				&:hover {
					opacity: 1;
					transform: scale(-1) translateZ(0);
				}
			}
		}
	}
}
