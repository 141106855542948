.cluster{
	float: left;
	position: relative;
	height: calc(100vh - 121px);

	.titles{
		position: relative;
		z-index: 5;
		padding: 2px 8px;
		margin: 20px 10px 25px;
		color: #eee;
		background: #fff;
		border-radius: 4px;
		float: left;
		h2{
			line-height: 1.2em;
		}
		h3{
			font-size: 0.8em;
			line-height: 1.2em;
		}
	}
	.boxes-wrap{
		position: relative;
		z-index: 2;
		height: calc(100vh - 200px);
		// width: 45vh;
		transform: rotate(180deg);
		margin: 0px 5px 0 0;
	}
	.boxes{
		position: relative;
		border-right: 2px solid #B3B3B3;
		padding-right: 0.5vh;
		opacity: 1;
		transition: opacity 300ms;

		&.dim{
			opacity: 0.3;
			pointer-events: none;
		}
	}
	.cluster-bg{
		width: 100%;
		height: calc(100vh - 171px);
		position: absolute;
		top: 0;
		background-size: cover;
		background-repeat: no-repeat;
	}
	&.active{
		z-index: 5;
	}
	&.notitle{
		.boxes-wrap{
			height: calc(100vh - 171px);
		}
		.titles{
			display: none;
		}
	}
}
