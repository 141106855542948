.ribbon {
    position: absolute;
    left: -5px;
    top: -5px;
    width: 70px;
    height: 70px;
    z-index: 1;
    pointer-events: none;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 70px solid #4592b7;
        border-bottom: 70px solid transparent;
        border-right: 70px solid transparent;
        z-index: 1;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 4px;
        border-top: 67px solid #7e7e7e;
        border-bottom: 67px solid transparent;
        border-right: 67px solid transparent;
        z-index: 0;
        opacity: 0.1;
    }

    &__icon {
        display: block;
        position: relative;
        top: 10px;
        left: 10px;
        color: $color-white;
        font-size: 25px;
        z-index: 2;

        &.icon-board-task {
            font-size: 25px;
        }

        &.icon-example {
            font-size: 16px;
        }

        &.icon-pin {
            font-size: 25px;
        }

        &.icon-group-task {
            font-size: 25px;
        }

        &.icon-individual-task {
            font-size: 25px;
        }

        &.icon-quote {
            font-size: 17px;
        }
    }
}
