.iframe-wrapper {
    max-width: 100%;
    height: auto;
    position: relative;

    &--audio {
        height: 120px;
        width: 100%;
    }
}
