.highlight {
	background: rgba(238, 238, 238, 0.7);
	visibility: hidden;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	z-index: 8;
	min-height: 100%;
	opacity: 0;

	.header {
		&.frieze {
			padding-top: 250px;
		}

		margin-top: 128px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		&.fixed {
			position: absolute !important;
			top: 0 !important;
		}

		.info {
			padding: 20px;
			margin-top: 100px;
			background: #383838;
			min-height: 0;
			color: #fff;
			max-width: 800px;
			margin: 0 auto;

			h2 {
				font-size: 1.6em;
				font-weight: bold;
				color: $color-orange;
			}

			p {
				margin: 5px 0;
				font-style: italic;
				font-size: 1.2em;
			}
		}
	}

	.items {
		max-width: 740px;
		min-height: 100%;
		margin: 0 auto;
		background: #fff;
		padding: 50px;
	}

	&.show {
		position: absolute;
		visibility: visible;
		animation: fade-in 500ms ease-out 75ms forwards;

		.items,
		.header {
			animation: slide-in 500ms ease-out 75ms;
		}
	}
}
