.dragger{
	top: 0;
	position: fixed;
	z-index: 15;
	.thumb{
		cursor: move;
		box-shadow: 0 1px 4px rgba(0,0,0,0.5);
		position: absolute;
		background: #ccc no-repeat;
		border-radius: 5px;
		z-index: 3;
		background-size: cover;
		width: 9vh;
		height: 9vh;
	}
	.listitem{
		cursor: move;
		box-shadow: 0 1px 4px rgba(0,0,0,0.5);
		position: absolute;
		background: #ccc no-repeat;
		z-index: 3;
		width: 200px;
	}
}
