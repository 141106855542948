.video{
	width: 100%;
	height: auto !important;
	position: relative;
	padding-bottom: 57.5%;
	margin-bottom: 20px;
	height: 0;
	iframe {
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
