.box{
	position: relative;
	float: right;
	width: 9vh;
	height: 9vh;
	margin: 0.5vh;
	border-radius: 5px;
	transform: rotate(-180deg) translateZ(0) scale(1,1);
	transition: opacity 300ms;
	.thumb{
		position: relative;
		background: #ccc no-repeat;
		border-radius: 5px;
		z-index: 3;
		background-size: cover;
		width: 9vh;
		height: 9vh;
		will-change: transform;
		cursor: pointer;
		&.theme, &.visited{
			width: 8.6vh;
			height: 8.6vh;
			border-width: 0.2vh;
			border-style: solid;
		}
	}
	&.dim{
		opacity: 0.3;
		pointer-events: none;
	}
	.info{
		min-width: 300px;
		min-height: 9vh;
		max-height: 190px;
		overflow: hidden;
		background-color: #ededed;
		opacity: 0;
		position: absolute;
		transform-origin: 45px 45px;
		transform: scale(0.9,0.9);
		top: -10px;
		left: -10px;
		transition: all 0.4s;
		padding: 10px 10px 10px calc(10vh + 10px);
		border-radius: 10px;
		pointer-events: none;
		transform: translateZ(0) scale(0.8, 0.8);
		p{
			margin: 0;
		}
		&.reverse{
			padding: 10px calc(10vh + 10px) 10px 15px;
			left: -325px;
			transform-origin: 345px 45px;
		}
	}
	&.hover{
		position: relative;
		z-index: 1000;
		.info{
			opacity: 1;
			transform: scale(1, 1);
			pointer-events: inherit;
		}
	}
	&.High{
		float: left;
		&, .thumb{
			width: 19vh;
			height: 19vh;
			&.theme, &.visited{
				width: 18.6vh;
				height: 18.6vh;
				border-width: 0.2vh;
				border-style: solid;
			}
		}
		.info{
			transform-origin: 90px 90px;
			padding: 10px 10px 10px calc(20vh + 10px);
			min-height: 19vh;
			max-height: 330px;
			&.reverse{
				transform-origin: 400px 90px;
				padding: 10px calc(20vh + 10px) 10px 15px;
				left: -325px;
			}
		}
	}
}
