.top-bar{
	width: 100%;
	height: 75px;
	position: fixed;
	width: 100%;
	background-color: #fff;
	z-index: 10;
	.filter-bar{
		border-top: 2px solid rgba(0,0,0,0.22);
		height: 1px;
		background: $color-orange;
		transition: all 300ms;
		width: 100%;
		top: -10px;
		overflow: hidden;

		&.active{
			height: 75px;
		}

		&.active-after {
			overflow: visible;

			.dropdown {
				opacity: 1;
			}
		}

		.dropdown {
			float: left;
			padding: 20px;
			width: 350px;
			margin-left: 47px;
			position: relative;
			box-sizing: border-box;
			opacity: 0;
			transition: opacity 175ms linear;

			&:before {
				content: '';
				display: block;
				width: calc(100% - 15px);
				height: 48px;
				background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%);
				position: absolute;
				left: 0;
				top: 8px;
				z-index: 8;
				opacity: 0;
				transition: opacity 175ms linear;
			}

			&:first-child {
				margin-left: 15px;
			}

			label {
				display: block;
				position: relative;
				z-index:2;
				color: #fff;
				padding-bottom: 4px;
				border-bottom: 2px solid #ffffff;
				cursor: pointer;
				user-select: none;
				position: relative;
				transition: color 175ms linear, border-color 175ms linear;
				z-index: 9;

				.arrow {
					display: block;
					position: absolute;
					right: 7px;
					top: 11px;
					font-size: 9px;
					transition: 225ms transform ease-out, color 175ms linear;
					transform: rotateZ(0deg);
					color: #fff;
				}
			}

			.list {
				background: #fff;
				/*overflow-x: hidden;
				overflow-y: auto;
				max-height: 300px;*/
				position: absolute;
				left: 0;
				top: 8px;
				padding: 44px 20px 20px 20px;
				box-sizing: border-box;
				width: 100%;
				opacity: 0;
				visibility: hidden;
				transition: visibility 0ms linear 175ms, opacity 175ms linear;
				z-index: 7;
				overflow-x: hidden;
				overflow-y: auto;
				max-height: 400px;

				> div {
					cursor: pointer;
					position: relative;
					padding-left: 25px;
					display: block;
					font-size: 17px;
					transition: color 175ms linear;

					> font {
						font-size: inherit !important;
					}

					&:hover {
						color: #000;
					}

					&.sel {
						color: #000;

						.radio {
							div {
								transform: scale(1) translateZ(0);
								opacity: 1;
							}
						}
					}
				}
				.dot{
					border-radius: 50%;
					height: 10px;
					width: 10px;
					display: block;
					box-shadow: 0 1px 2px rgba(0,0,0,0.3);
					position: absolute;
					right: -4px;
					top: 8px;
				}

				.radio {
					display: block;
					width: 15px;
					height: 15px;
					border: 1px solid #333;
					border-radius: 50%;
					position: absolute;
					left: 0px;
					top: 5px;

					div {
						margin: 3px;
						width: 9px;
						height: 9px;
						border-radius: 50%;
						transform: scale(0.75) translateZ(0);
						opacity: 0;
						transition: opacity 125ms linear, transform 125ms ease-out;
						backface-visibility: hidden;
					}
				}
			}
			&.active{
				&:before {
					opacity: 1;
				}
				label{
					color: #000;
					border-bottom-color: transparent;

					.arrow {
						transform: rotateZ(180deg);
					}
				}
				.list{
					visibility: visible;
					opacity: 1;
					transition-delay: 0ms;
				}
			}
		}
	}
	.gray{
		height: 50px;
		background-color: #383838;
	}

	.back{
		display: inline-block;
		width: 45px;
		position: relative;
		left: 0;
		top: -2px;
		height: 35px;
		cursor: pointer;
		background: url(../assets/images/icons.svg) -150px -50px no-repeat;
		float: left;
		opacity: 1;
		// transition: opacity 0.5s, left 0.5s, width 0.5s;
	}
	.white{
		position: relative;
		height: 75px;
		position: relative;
		.banner-bg{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-position: left top;
			background-repeat: no-repeat;
			pointer-events: none;
		}
	}
	h1{
		position: relative;
		z-index: 1;
		float: left;
		font-size: 26px;
		font-family: franklin-gothic-urw-comp, sans-serif;
		color: #383838;
		margin: 22px 0 0 30px;
		font-weight: normal;
		cursor: pointer;

		&.hidden{
			cursor: default;
			.back{
				left: -45px;
				opacity: 0;
				width: 0;
			}
		}
	}
	.buttons{
		position: relative;
		float: right;
		height: 35px;
		margin: 20px;
		z-index: 1;
		&.hide-filter{
			.btn.filter{
				display: none;
			}
			.comp-box{
				right: 40px;
			}
		}
	}
	.btn{
		display: inline-block;
		width: 35px;
		margin-right: 10px;
		height: 35px;
		cursor: pointer;
		#border, #icon{
			transition: all 300ms;
		}
		&:hover, &.active{
			#border{
				stroke: $color-orange;
			}
			#icon{
				fill: $color-orange;
			}
		}
		&.active{
			.find-box, .comp-box{
				display: block;
			}
		}
	}
	.find-box{
		display: none;
		position: absolute;
		right: -10px;
		border-top: 3px solid #333;
		top: 55px;
		width: 300px;
		padding: 20px;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.comp-box{
		display: none;
		position: absolute;
		right: 90px;
		border-top: 3px solid #333;
		top: 55px;
		width: 300px;
		padding: 20px;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		.comp1,.comp2{
			width: 136px;
			height: 100px;
			float: left;
			border-radius: 5px;
			border: 2px dashed #ddd;
			background-size: cover;
			background-repeat: no-repeat;
			&.active{
				border: none;
				height: 104px;
				width: 140px;
			}
		}
		.comp1{
			margin-right: 20px;
		}
		button{
			background: $color-orange;
			color: rgba(255,255,255, 0.5);
			padding: 10px;
			width: 100%;
			margin-top: 20px;
			font-size: 0.8em;
			border: none;
			border-radius: 5px;
			&.active{
				color: #fff;
				cursor: pointer;
			}
		}
	}
	.find-box, .comp-box{
		cursor: default;
		.tri{
			position: absolute;
			right: 27px;
			top: -10px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #333;
		}
	}
}
