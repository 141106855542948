.panes{
	margin-bottom: 20px;
	.info, .image, .column, .sequence{
		margin-bottom: 0;
	}
	.tabs>div{
		float: left;
		padding: 10px 20px;
		color: #000;
		cursor: pointer;
		&.active{
			background-color: #eee;
			color: $color-orange;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
	}
	.tabs-con{
		padding: 20px;
		border: 1px solid #eee;
		border-radius: 5px;
		border-top-left-radius: 0;
	}
}
