.ordforklaring {
	color: $color-orange;
	cursor: pointer;
	display: inline-block;
}

.ord-tooltip {
	position: fixed;
	left: 0;
	top: 0;
	width: 300px;
	color: #666;
	padding: 5px 10px;
	display: block;
	min-width: 150px;
	z-index: 1;
	border-radius: 5px;
	font-size: 16px;
	line-height: 1.5;
	background: rgba(240, 240, 240, 0.9);
	opacity: 0;
	visibility: hidden;
	transition: visibility 0ms linear 175ms;
	animation: tooltip-out 175ms ease-out forwards;
	pointer-events: none;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.ordforklaring:hover + .ord-tooltip {
	visibility: visible;
	transition-delay: 0ms;
	animation: tooltip-in 175ms ease-out forwards;
}
