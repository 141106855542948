@keyframes load {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes slide-in {
  0% {
    transform: translateX(150px); }
  100% {
    transform: translateX(0px); } }

@keyframes slide-out {
  0% {
    transform: translateX(0px); }
  100% {
    transform: translateX(150px0px); } }

@keyframes tooltip-in {
  0% {
    transform: scale(0.9) translateY(15px);
    transform-origin: center center;
    opacity: 0; }
  100% {
    transform: scale(1) translateY(0px);
    transform-origin: center center;
    opacity: 1; } }

@keyframes tooltip-out {
  0% {
    transform: scale(1) translateY(0px);
    transform-origin: center center;
    opacity: 1; }
  100% {
    transform: scale(0.9) translateY(15px);
    transform-origin: center center;
    opacity: 0; } }

@font-face {
  font-family: 'icomoon';
  src: url("../assets/fonts/icons/icomoon.woff") format("woff"), url("../assets/fonts/icons/icomoon.ttf") format("truetype"), url("../assets/fonts/icons/icomoon.svg#icomoonregular") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class*="-circled"] {
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0); }

[class^="icon-"][class*="-circled"]::after,
[class*=" icon-"][class*="-circled"]::after {
  box-sizing: border-box;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border-color: inherit; }

.icon-student-tool:before {
  content: "\e900"; }

.icon-factbox:before {
  content: "\e906"; }

.icon-external-link:before {
  content: "\e912"; }

.icon-group-task:before {
  content: "\e916"; }

.icon-individual-task:before {
  content: "\e917"; }

.icon-pair-task:before {
  content: "\e918"; }

.icon-class-task:before {
  content: "\e919"; }

.icon-arrow-down:before {
  content: "\e612"; }

.icon-task:before {
  content: "\e901"; }

.hide {
  display: none; }

body, html {
  padding: 0;
  margin: 0;
  font-family: franklin-gothic-urw-comp, sans-serif;
  line-height: 1.5em;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #666;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }

::-webkit-scrollbar {
  -webkit-appearance: none; }

::-webkit-scrollbar:horizontal {
  height: 0px; }

::-webkit-scrollbar:vertical {
  height: 16px; }

::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #ccc !important; }

::-webkit-scrollbar-corner {
  -webkit-appearance: none;
  background: #ccc !important; }

::-webkit-scrollbar-thumb {
  -webkit-appearance: none;
  background: #383838 !important; }

input {
  font-family: franklin-gothic-urw-comp, sans-serif;
  line-height: 1.5em;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  outline: none;
  width: 96%;
  border: none;
  padding: 2%; }

h2, h3 {
  font-size: 1.2em;
  margin: 0;
  font-weight: 300;
  color: #383838; }

h1, h2, h3, p {
  user-select: default !important; }

#scroll-eye, #next-arrow, #prev-arrow {
  fill: #DC4320; }

.list-sec {
  background: #eee;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 150px);
  display: none;
  padding-top: 150px;
  z-index: 9; }
  .list-sec .periods, .list-sec .articles, .list-sec .article {
    background: #fff;
    float: left;
    height: 100%;
    width: 32%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  .list-sec .periods, .list-sec .articles {
    margin-right: 2%; }
  .list-sec .article .items {
    padding: 20px; }

.listitem {
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #eee; }
  .listitem h3 {
    font-size: 0.7em;
    line-height: 1em;
    margin-bottom: 0.2em; }
  .listitem.active {
    background: url(../assets/images/list-arrow.svg) right center no-repeat; }
    .listitem.active h2 {
      color: #DC4320; }

.info, .image, .column, .sequence {
  margin-bottom: 40px; }

.search-item {
  min-height: 50px;
  margin: 10px 0;
  cursor: pointer; }
  .search-item .thumb {
    float: left;
    width: 50px;
    margin-right: 10px;
    height: 50px;
    border-radius: 5px; }
  .search-item h3 {
    margin-bottom: 0; }
  .search-item p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none; }
  .search-item p:first-child {
    display: block; }

.compact .column .col1, .compact .column .col2 {
  float: none;
  width: 100%;
  margin-left: 0;
  margin-right: 0; }

.compact .header.frieze {
  height: 300px;
  position: relative;
  background-size: cover; }
  .compact .header.frieze .info {
    position: absolute;
    bottom: 0;
    width: 88%;
    background: #444; }

.compact .header > .info {
  background: #444;
  padding: 10px 20px;
  margin-bottom: 0; }
  .compact .header > .info h2 {
    color: #DC4320; }
  .compact .header > .info p {
    margin: 0;
    color: #fff; }

.bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover; }

.clear {
  clear: both; }

@media screen and (max-width: 700px) {
  body {
    background-color: #eee; }
  .list-sec {
    width: 100%;
    display: none;
    padding-top: 112px;
    z-index: 9;
    overflow-y: hidden;
    background: none;
    height: auto; }
    .list-sec .periods, .list-sec .articles, .list-sec .article {
      float: left;
      width: 100%;
      overflow: hidden;
      height: auto; }
    .list-sec .periods, .list-sec .articles {
      margin-right: 0; }
    .list-sec .article .items {
      padding: 20px; }
  .top-bar {
    height: 60px;
    top: 0;
    left: 0; }
    .top-bar .white {
      height: 60px;
      background-color: #fff; }
      .top-bar .white h1 {
        margin: 15px 0 0 20px;
        line-height: 30px; }
    .top-bar .buttons {
      display: none; } }

.loader {
  z-index: 1000;
  margin: -51px 0 0 -31px;
  left: 50%;
  font-size: 10px;
  position: absolute;
  top: 50%;
  text-indent: -9999em;
  border: 3px solid rgba(220, 67, 32, 0.25);
  border-left: 3px solid #DC4320;
  transform: translateZ(0);
  animation: load 1.1s infinite linear;
  transition: opacity 300ms, visibility 300ms, transform 300ms;
  opacity: 1;
  visibility: visible; }
  .loader.hide {
    opacity: 0;
    visibility: hidden; }

.loader-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 26px;
  margin-left: -100px;
  width: 200px;
  font-size: 14px;
  line-height: 1em;
  text-align: center; }

.loader,
.loader:after {
  border-radius: 50%;
  width: 60px;
  height: 60px; }

.video {
  width: 100%;
  height: auto !important;
  position: relative;
  padding-bottom: 57.5%;
  margin-bottom: 20px;
  height: 0; }
  .video iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.audio {
  position: relative; }
  .audio iframe {
    height: 120px;
    width: 100%;
    border: none; }

.sequence {
  position: relative;
  word-break: break-word;
  hyphens: auto; }
  .sequence .info,
  .sequence .image,
  .sequence .column,
  .sequence .sequence {
    margin-bottom: 0; }
  .sequence .tabs-scroll {
    position: relative;
    overflow: hidden;
    padding-bottom: 5px;
    margin-bottom: 15px; }
  .sequence .next,
  .sequence .prev {
    width: 100px;
    height: 74px;
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
    cursor: pointer; }
    .sequence .next.active,
    .sequence .prev.active {
      opacity: 1;
      visibility: visible; }
  .sequence .next {
    right: 0; }
  .sequence .prev {
    left: 0; }
  .sequence .tabs > div {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    float: left;
    width: 110px;
    height: 70px;
    margin-right: 20px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px; }
    .sequence .tabs > div.active {
      border-color: #DC4320; }
  .sequence .tabs-con {
    margin-bottom: 20px;
    position: relative; }
    .sequence .tabs-con .arrow-left,
    .sequence .tabs-con .arrow-right {
      display: block !important;
      width: 73px;
      height: 91px;
      background: url(../assets/images/arrow.png) no-repeat center center;
      background-size: contain;
      cursor: default;
      position: absolute;
      left: 11px;
      top: 50%;
      margin: -45px 0 0 0;
      transform: scale(0.8) translateZ(0);
      transform-origin: center center;
      pointer-events: none;
      user-select: none;
      transition: opacity 125ms linear, transform 125ms ease-out;
      opacity: 0.25;
      backface-visibility: hidden; }
    .sequence .tabs-con .arrow-right {
      left: auto;
      right: 11px; }
      .sequence .tabs-con .arrow-right.active {
        opacity: 0.85;
        pointer-events: all;
        cursor: pointer; }
        .sequence .tabs-con .arrow-right.active:hover {
          opacity: 1;
          transform: scale(1) translateZ(0); }
    .sequence .tabs-con .arrow-left {
      transform: scale(-0.8); }
      .sequence .tabs-con .arrow-left.active {
        opacity: 0.85;
        pointer-events: all;
        cursor: pointer; }
        .sequence .tabs-con .arrow-left.active:hover {
          opacity: 1;
          transform: scale(-1) translateZ(0); }

.image {
  overflow: hidden;
  position: relative; }
  .image img {
    width: 100%;
    vertical-align: middle;
    height: auto; }
  .image .img {
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative; }
  .image h3, .image .des {
    font-style: italic;
    margin-bottom: 5px;
    margin-top: 0; }
  .image.zoom .img {
    cursor: zoom-in; }
    .image.zoom .img.down {
      cursor: zoom-out; }

.panes {
  margin-bottom: 20px; }
  .panes .info, .panes .image, .panes .column, .panes .sequence {
    margin-bottom: 0; }
  .panes .tabs > div {
    float: left;
    padding: 10px 20px;
    color: #000;
    cursor: pointer; }
    .panes .tabs > div.active {
      background-color: #eee;
      color: #DC4320;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
  .panes .tabs-con {
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 5px;
    border-top-left-radius: 0; }

.column .col1, .column .col2 {
  float: left;
  width: 46%; }

.column .col1 {
  margin-right: 4%; }

.column .col2 {
  margin-left: 4%; }

.ordforklaring {
  color: #DC4320;
  cursor: pointer;
  display: inline-block; }

.ord-tooltip {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  color: #666;
  padding: 5px 10px;
  display: block;
  min-width: 150px;
  z-index: 1;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  background: rgba(240, 240, 240, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0ms linear 175ms;
  animation: tooltip-out 175ms ease-out forwards;
  pointer-events: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25); }

.ordforklaring:hover + .ord-tooltip {
  visibility: visible;
  transition-delay: 0ms;
  animation: tooltip-in 175ms ease-out forwards; }

.highlight {
  background: rgba(238, 238, 238, 0.7);
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 8;
  min-height: 100%;
  opacity: 0; }
  .highlight .header {
    margin-top: 128px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
    .highlight .header.frieze {
      padding-top: 250px; }
    .highlight .header.fixed {
      position: absolute !important;
      top: 0 !important; }
    .highlight .header .info {
      padding: 20px;
      margin-top: 100px;
      background: #383838;
      min-height: 0;
      color: #fff;
      max-width: 800px;
      margin: 0 auto; }
      .highlight .header .info h2 {
        font-size: 1.6em;
        font-weight: bold;
        color: #DC4320; }
      .highlight .header .info p {
        margin: 5px 0;
        font-style: italic;
        font-size: 1.2em; }
  .highlight .items {
    max-width: 740px;
    min-height: 100%;
    margin: 0 auto;
    background: #fff;
    padding: 50px; }
  .highlight.show {
    position: absolute;
    visibility: visible;
    animation: fade-in 500ms ease-out 75ms forwards; }
    .highlight.show .items,
    .highlight.show .header {
      animation: slide-in 500ms ease-out 75ms; }

.compare {
  background: #eee;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 8;
  height: calc(100% - 128px);
  padding-top: 128px; }
  .compare .compare-left, .compare .compare-right {
    float: left;
    width: 50%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  .compare .items {
    padding: 20px; }
    .compare .items p:first-child {
      padding-top: 20px;
      margin-top: 0; }
  .compare .fixed .header {
    z-index: 3;
    position: absolute !important;
    width: 50%;
    top: 128px; }
    .compare .fixed .header.frieze {
      top: -98px !important; }
  .compare .compare-left .items {
    margin-right: 2%;
    background: #fff; }
  .compare .compare-left .header .info {
    padding-left: 3%;
    width: 89%; }
  .compare .compare-left .header.frieze .info {
    margin-right: 2%;
    width: 89%; }
  .compare .compare-right .items {
    margin-left: 2%;
    background: #fff; }
  .compare .compare-right .header .info {
    padding-left: 3%;
    width: 89%;
    margin-left: 2%; }
  .compare .compare-right .header.frieze .info {
    margin-left: 2%;
    padding-left: 3%;
    width: 89%; }

.dragger {
  top: 0;
  position: fixed;
  z-index: 15; }
  .dragger .thumb {
    cursor: move;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    background: #ccc no-repeat;
    border-radius: 5px;
    z-index: 3;
    background-size: cover;
    width: 9vh;
    height: 9vh; }
  .dragger .listitem {
    cursor: move;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    background: #ccc no-repeat;
    z-index: 3;
    width: 200px; }

.timeline {
  position: relative;
  padding-top: 77px; }
  .timeline .scroll {
    z-index: 6;
    position: fixed;
    height: 43px;
    background-color: #383838;
    bottom: 0;
    width: 100%;
    user-select: none; }
    .timeline .scroll .full {
      position: relative;
      width: 80%;
      height: 43px;
      margin: 0px auto; }
    .timeline .scroll .cur {
      position: absolute;
      user-select: none;
      top: 7px;
      left: 0;
      height: 25px;
      width: 0;
      background: rgba(220, 67, 32, 0.2);
      border: 2px solid #DC4320;
      border-radius: 5px;
      display: none;
      z-index: 1;
      text-align: center;
      cursor: move; }
      .timeline .scroll .cur svg {
        vertical-align: middle;
        pointer-events: none; }
    .timeline .scroll .btn-wrap {
      float: left;
      height: 30px; }
    .timeline .scroll .btn {
      margin: 12px 6px;
      background-color: #2c2c2c;
      height: 19px;
      border-radius: 3px;
      position: relative;
      text-align: center; }
      .timeline .scroll .btn .tooltip {
        display: none;
        position: absolute;
        width: 150px;
        margin-left: -79px;
        font-size: 90%;
        line-height: 100%;
        padding: 5px;
        border-radius: 3px;
        bottom: 35px;
        background: #fff;
        box-shadow: 0 1px 3px #000; }
        .timeline .scroll .btn .tooltip h3 {
          font-size: 90%;
          line-height: 100%; }
        .timeline .scroll .btn .tooltip .tri {
          position: absolute;
          left: calc(50% - 8px);
          bottom: -8px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #fff; }
      .timeline .scroll .btn:hover .tooltip {
        display: inline-block; }
    .timeline .scroll.active .cur {
      display: block; }

.cluster {
  float: left;
  position: relative;
  height: calc(100vh - 121px); }
  .cluster .titles {
    position: relative;
    z-index: 5;
    padding: 2px 8px;
    margin: 20px 10px 25px;
    color: #eee;
    background: #fff;
    border-radius: 4px;
    float: left; }
    .cluster .titles h2 {
      line-height: 1.2em; }
    .cluster .titles h3 {
      font-size: 0.8em;
      line-height: 1.2em; }
  .cluster .boxes-wrap {
    position: relative;
    z-index: 2;
    height: calc(100vh - 200px);
    transform: rotate(180deg);
    margin: 0px 5px 0 0; }
  .cluster .boxes {
    position: relative;
    border-right: 2px solid #B3B3B3;
    padding-right: 0.5vh;
    opacity: 1;
    transition: opacity 300ms; }
    .cluster .boxes.dim {
      opacity: 0.3;
      pointer-events: none; }
  .cluster .cluster-bg {
    width: 100%;
    height: calc(100vh - 171px);
    position: absolute;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat; }
  .cluster.active {
    z-index: 5; }
  .cluster.notitle .boxes-wrap {
    height: calc(100vh - 171px); }
  .cluster.notitle .titles {
    display: none; }

.box {
  position: relative;
  float: right;
  width: 9vh;
  height: 9vh;
  margin: 0.5vh;
  border-radius: 5px;
  transform: rotate(-180deg) translateZ(0) scale(1, 1);
  transition: opacity 300ms; }
  .box .thumb {
    position: relative;
    background: #ccc no-repeat;
    border-radius: 5px;
    z-index: 3;
    background-size: cover;
    width: 9vh;
    height: 9vh;
    will-change: transform;
    cursor: pointer; }
    .box .thumb.theme, .box .thumb.visited {
      width: 8.6vh;
      height: 8.6vh;
      border-width: 0.2vh;
      border-style: solid; }
  .box.dim {
    opacity: 0.3;
    pointer-events: none; }
  .box .info {
    min-width: 300px;
    min-height: 9vh;
    max-height: 190px;
    overflow: hidden;
    background-color: #ededed;
    opacity: 0;
    position: absolute;
    transform-origin: 45px 45px;
    transform: scale(0.9, 0.9);
    top: -10px;
    left: -10px;
    transition: all 0.4s;
    padding: 10px 10px 10px calc(10vh + 10px);
    border-radius: 10px;
    pointer-events: none;
    transform: translateZ(0) scale(0.8, 0.8); }
    .box .info p {
      margin: 0; }
    .box .info.reverse {
      padding: 10px calc(10vh + 10px) 10px 15px;
      left: -325px;
      transform-origin: 345px 45px; }
  .box.hover {
    position: relative;
    z-index: 1000; }
    .box.hover .info {
      opacity: 1;
      transform: scale(1, 1);
      pointer-events: inherit; }
  .box.High {
    float: left; }
    .box.High, .box.High .thumb {
      width: 19vh;
      height: 19vh; }
      .box.High.theme, .box.High.visited, .box.High .thumb.theme, .box.High .thumb.visited {
        width: 18.6vh;
        height: 18.6vh;
        border-width: 0.2vh;
        border-style: solid; }
    .box.High .info {
      transform-origin: 90px 90px;
      padding: 10px 10px 10px calc(20vh + 10px);
      min-height: 19vh;
      max-height: 330px; }
      .box.High .info.reverse {
        transform-origin: 400px 90px;
        padding: 10px calc(20vh + 10px) 10px 15px;
        left: -325px; }

.top-bar {
  width: 100%;
  height: 75px;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 10; }
  .top-bar .filter-bar {
    border-top: 2px solid rgba(0, 0, 0, 0.22);
    height: 1px;
    background: #DC4320;
    transition: all 300ms;
    width: 100%;
    top: -10px;
    overflow: hidden; }
    .top-bar .filter-bar.active {
      height: 75px; }
    .top-bar .filter-bar.active-after {
      overflow: visible; }
      .top-bar .filter-bar.active-after .dropdown {
        opacity: 1; }
    .top-bar .filter-bar .dropdown {
      float: left;
      padding: 20px;
      width: 350px;
      margin-left: 47px;
      position: relative;
      box-sizing: border-box;
      opacity: 0;
      transition: opacity 175ms linear; }
      .top-bar .filter-bar .dropdown:before {
        content: '';
        display: block;
        width: calc(100% - 15px);
        height: 48px;
        background: linear-gradient(to bottom, white 0%, white 85%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        left: 0;
        top: 8px;
        z-index: 8;
        opacity: 0;
        transition: opacity 175ms linear; }
      .top-bar .filter-bar .dropdown:first-child {
        margin-left: 15px; }
      .top-bar .filter-bar .dropdown label {
        display: block;
        position: relative;
        z-index: 2;
        color: #fff;
        padding-bottom: 4px;
        border-bottom: 2px solid #ffffff;
        cursor: pointer;
        user-select: none;
        position: relative;
        transition: color 175ms linear, border-color 175ms linear;
        z-index: 9; }
        .top-bar .filter-bar .dropdown label .arrow {
          display: block;
          position: absolute;
          right: 7px;
          top: 11px;
          font-size: 9px;
          transition: 225ms transform ease-out, color 175ms linear;
          transform: rotateZ(0deg);
          color: #fff; }
      .top-bar .filter-bar .dropdown .list {
        background: #fff;
        /*overflow-x: hidden;
				overflow-y: auto;
				max-height: 300px;*/
        position: absolute;
        left: 0;
        top: 8px;
        padding: 44px 20px 20px 20px;
        box-sizing: border-box;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0ms linear 175ms, opacity 175ms linear;
        z-index: 7;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 400px; }
        .top-bar .filter-bar .dropdown .list > div {
          cursor: pointer;
          position: relative;
          padding-left: 25px;
          display: block;
          font-size: 17px;
          transition: color 175ms linear; }
          .top-bar .filter-bar .dropdown .list > div > font {
            font-size: inherit !important; }
          .top-bar .filter-bar .dropdown .list > div:hover {
            color: #000; }
          .top-bar .filter-bar .dropdown .list > div.sel {
            color: #000; }
            .top-bar .filter-bar .dropdown .list > div.sel .radio div {
              transform: scale(1) translateZ(0);
              opacity: 1; }
        .top-bar .filter-bar .dropdown .list .dot {
          border-radius: 50%;
          height: 10px;
          width: 10px;
          display: block;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
          position: absolute;
          right: -4px;
          top: 8px; }
        .top-bar .filter-bar .dropdown .list .radio {
          display: block;
          width: 15px;
          height: 15px;
          border: 1px solid #333;
          border-radius: 50%;
          position: absolute;
          left: 0px;
          top: 5px; }
          .top-bar .filter-bar .dropdown .list .radio div {
            margin: 3px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            transform: scale(0.75) translateZ(0);
            opacity: 0;
            transition: opacity 125ms linear, transform 125ms ease-out;
            backface-visibility: hidden; }
      .top-bar .filter-bar .dropdown.active:before {
        opacity: 1; }
      .top-bar .filter-bar .dropdown.active label {
        color: #000;
        border-bottom-color: transparent; }
        .top-bar .filter-bar .dropdown.active label .arrow {
          transform: rotateZ(180deg); }
      .top-bar .filter-bar .dropdown.active .list {
        visibility: visible;
        opacity: 1;
        transition-delay: 0ms; }
  .top-bar .gray {
    height: 50px;
    background-color: #383838; }
  .top-bar .back {
    display: inline-block;
    width: 45px;
    position: relative;
    left: 0;
    top: -2px;
    height: 35px;
    cursor: pointer;
    background: url(../assets/images/icons.svg) -150px -50px no-repeat;
    float: left;
    opacity: 1; }
  .top-bar .white {
    position: relative;
    height: 75px;
    position: relative; }
    .top-bar .white .banner-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: left top;
      background-repeat: no-repeat;
      pointer-events: none; }
  .top-bar h1 {
    position: relative;
    z-index: 1;
    float: left;
    font-size: 26px;
    font-family: franklin-gothic-urw-comp, sans-serif;
    color: #383838;
    margin: 22px 0 0 30px;
    font-weight: normal;
    cursor: pointer; }
    .top-bar h1.hidden {
      cursor: default; }
      .top-bar h1.hidden .back {
        left: -45px;
        opacity: 0;
        width: 0; }
  .top-bar .buttons {
    position: relative;
    float: right;
    height: 35px;
    margin: 20px;
    z-index: 1; }
    .top-bar .buttons.hide-filter .btn.filter {
      display: none; }
    .top-bar .buttons.hide-filter .comp-box {
      right: 40px; }
  .top-bar .btn {
    display: inline-block;
    width: 35px;
    margin-right: 10px;
    height: 35px;
    cursor: pointer; }
    .top-bar .btn #border, .top-bar .btn #icon {
      transition: all 300ms; }
    .top-bar .btn:hover #border, .top-bar .btn.active #border {
      stroke: #DC4320; }
    .top-bar .btn:hover #icon, .top-bar .btn.active #icon {
      fill: #DC4320; }
    .top-bar .btn.active .find-box, .top-bar .btn.active .comp-box {
      display: block; }
  .top-bar .find-box {
    display: none;
    position: absolute;
    right: -10px;
    border-top: 3px solid #333;
    top: 55px;
    width: 300px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .top-bar .comp-box {
    display: none;
    position: absolute;
    right: 90px;
    border-top: 3px solid #333;
    top: 55px;
    width: 300px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
    .top-bar .comp-box .comp1, .top-bar .comp-box .comp2 {
      width: 136px;
      height: 100px;
      float: left;
      border-radius: 5px;
      border: 2px dashed #ddd;
      background-size: cover;
      background-repeat: no-repeat; }
      .top-bar .comp-box .comp1.active, .top-bar .comp-box .comp2.active {
        border: none;
        height: 104px;
        width: 140px; }
    .top-bar .comp-box .comp1 {
      margin-right: 20px; }
    .top-bar .comp-box button {
      background: #DC4320;
      color: rgba(255, 255, 255, 0.5);
      padding: 10px;
      width: 100%;
      margin-top: 20px;
      font-size: 0.8em;
      border: none;
      border-radius: 5px; }
      .top-bar .comp-box button.active {
        color: #fff;
        cursor: pointer; }
  .top-bar .find-box, .top-bar .comp-box {
    cursor: default; }
    .top-bar .find-box .tri, .top-bar .comp-box .tri {
      position: absolute;
      right: 27px;
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #333; }

.iframe-wrapper {
  max-width: 100%;
  height: auto;
  position: relative; }
  .iframe-wrapper--audio {
    height: 120px;
    width: 100%; }

.snippet {
  background: rgba(90, 60, 40, 0.08);
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 40px 42px;
  position: relative; }

.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 70px;
  height: 70px;
  z-index: 1;
  pointer-events: none; }
  .ribbon::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 70px solid #4592b7;
    border-bottom: 70px solid transparent;
    border-right: 70px solid transparent;
    z-index: 1; }
  .ribbon::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    border-top: 67px solid #7e7e7e;
    border-bottom: 67px solid transparent;
    border-right: 67px solid transparent;
    z-index: 0;
    opacity: 0.1; }
  .ribbon__icon {
    display: block;
    position: relative;
    top: 10px;
    left: 10px;
    color: #FFF;
    font-size: 25px;
    z-index: 2; }
    .ribbon__icon.icon-board-task {
      font-size: 25px; }
    .ribbon__icon.icon-example {
      font-size: 16px; }
    .ribbon__icon.icon-pin {
      font-size: 25px; }
    .ribbon__icon.icon-group-task {
      font-size: 25px; }
    .ribbon__icon.icon-individual-task {
      font-size: 25px; }
    .ribbon__icon.icon-quote {
      font-size: 17px; }

.tabs-con > div {
  display: none !important; }
  .tabs-con > div.active {
    display: block !important; }
