@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes slide-in {
  0% {
    transform: translateX(150px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(150px0px);
  }
}


@keyframes tooltip-in {
  0% {
    transform: scale(0.9) translateY(15px);
    transform-origin: center center;
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    transform-origin: center center;
    opacity: 1;
  }
}

@keyframes tooltip-out {
  0% {
    transform: scale(1) translateY(0px);
    transform-origin: center center;
    opacity: 1;
  }

  100% {
    transform: scale(0.9) translateY(15px);
    transform-origin: center center;
    opacity: 0;
  }
}
