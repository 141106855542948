@media screen and (max-width: $mobile-breakpoint) {
	body{
		background-color: #eee;
	}
	.list-sec{
		width: 100%;
		display: none;
		padding-top: 112px;
		z-index: 9;
		overflow-y: hidden;
		background: none;
		height: auto;
		.periods, .articles, .article{
			float: left;
			width: 100%;
			overflow: hidden;
			height: auto;
		}
		.periods, .articles{
			margin-right: 0;
		}
		.article{
			.items{
				padding: 20px;
			}
		}
	}
	.top-bar{
		height: 60px;
		top: 0;
		left: 0;
		.white{
			height: 60px;
			background-color: #fff;
			h1{
				margin: 15px 0 0 20px;
				line-height: 30px;
			}
		}
		.buttons{
			display: none;
		}
	}
}
